import { createApp } from 'vue'
import App from './App.vue'

///--active page start---///


   
   
///--active page end---///
const app = createApp(App);
import VueWriter from "vue-writer"

app.use(VueWriter)
app.mount('#app');

 